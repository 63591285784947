.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.main form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.main button {
    background-color: #0140A9 !important;
    color: white !important;
    text-transform: uppercase !important;
    font-size: 16px !important;
    padding: 20px 30px !important;
    border-radius: 40px !important;
    font-weight: 600 !important;
    text-align: center !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    font-family: "Unbounded", sans-serif !important;
}
.main button:hover {
    background-color: #033791 !important;
}