.main {
  font-family: "Unbounded", sans-serif;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #151515;
  overflow-x: hidden;
}
.main h3 {
  font-size: 26px;
  font-weight: 400;
}
.home {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("./Assets/home.jpg");
  background-position: center;
  padding-top: 70px;
  box-sizing: border-box;
  background-size: cover;
}
.homeContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.homeContainer h1 {
  font-weight: medium;
  font-size: 110px;
}
.homeContainer h2 {
  text-align: center;
  font-size: 28px;
  font-weight: 400;
  margin-top: 8px;
}
.homeFull {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 90px;
  margin-bottom: 60px;
}
.homeLogos {
  display: flex;
  align-items: center;
  justify-content: center;
}
.homeLogos p {
  margin-right: 24px;
}
.homeFullText {
  max-width: 370px;
  font-size: 20px;
  font-weight: medium;
}
.homeCities {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(21, 21, 21, .8);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 100%;
  margin-top: auto;
  padding: 40px 0;
  box-sizing: border-box;
}
.citiesContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.field {
  width: 100%;
  display: flex;
  gap: 20px;
}
.innerField {
  display: flex;
  gap: 20px;
  width: 100%;
}
.col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  width: 100%;
}
.selectors {
  display: flex;
  gap: 20px;
  width: 100%;
}
.block {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
}
.cityButt {
  background-color: transparent !important;
  font-family: "Unbounded", sans-serif !important;
  color: white !important;
  padding: 22px 26px !important;
  box-sizing: border-box !important;
  border: 1px solid rgba(255, 255, 255, .2) !important;
  border-radius: 10px !important;
  width: 100% !important;
  text-align: left !important;
  display: flex !important;
  justify-content: flex-start !important;
  text-transform: initial !important;
  font-size: 18px !important;
  font-weight: 400 !important;
}
.activeButt {
  background-color: #0140A9 !important;
  color: white !important;
  border: 1px solid #0140A9 !important;
}
.label {
  font-size: 18px;
  font-weight: 400;
}
.block p {
  font-size: 24px;
  font-weight: 600;
}
.pre {
  background-color: transparent !important;
  font-family: "Unbounded", sans-serif !important;
  color: white !important;
  padding: 22px 26px !important;
  box-sizing: border-box !important;
  border: 1px solid rgba(255, 255, 255, .2);
  border-radius: 10px !important;
  width: 100% !important;
  text-align: left !important;
  display: flex !important;
  gap: 4px;
  flex-wrap: wrap;
  text-transform: initial !important;
  font-weight: 400 !important;
  align-items: center;
}
.pre p {
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px !important;
}


/* ONLINE */
.online {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}
.moon {
  position: absolute;
  right: 0;
  top: -30px;
}
.onlineContainer {
  padding-top: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  z-index: 1;
}
.onlineSpeaker {
  width: 100%;
  display: flex;
  gap: 20px;
  margin-top: 70px;
}
.onlineSpeakerInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.onlineText {
  font-size: 16px;
  font-weight: 400;
}
.onlineName {
  font-size: 80px;
  text-transform: uppercase;
  font-weight: 400;
}
.program {
  width: 100%;
  margin-top: 120px;
  position: relative;
}
.earth {
  position: absolute;
  left: 0;
  top: 50%;
}
.programBlock {
  background-color: white;
  padding: 40px;
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.programWeek {
  display: flex;
  align-items: center;
}
.programWeek p {
  color: #151515;
  font-size: 16px;
  border: 1px solid #151515;
  padding: 10px 20px;
  border-radius: 50px
}
.programBlock h4 {
  font-size: 36px;
  font-weight: 400;
  color: #0140A9;
  text-transform: uppercase;
}
.lessons {
  font-size: 20px;
  color: #151515;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.lessons p {
  padding-top: 0px;
  border-top: 0;
}
.lessons p + p {
  padding-top: 20px;
  border-top: 1px solid rgba(21, 21, 21, .2);
  width: 100%;
}
.programCount {
  display: flex;
  width: 100%;
  gap: 12px;
  align-items: center;
}
.feature {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid rgba(21, 21, 21, .2);
  border-radius: 40px;
  padding: 7.5px 16px;
  width: 100%;
}
.feature p, .feature strong {
  color: #151515;
  font-size: 16px;
}
.feature svg {
  color: rgba(21, 21, 21, .2);
  font-size: 20px;
}

/* OFFLINE */
.offline {
  width: 100%;
  height: fit-content;
  background-image: url("./Assets/bg.JPG");
  background-attachment: fixed;
  background-size: 100vh;
  background-position: center;
  position: relative;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.gradient {
  width: 100%;
  height: 200px;
  background: rgb(21,21,21);
  background: linear-gradient(180deg, rgba(21,21,21,1) 0%, rgba(21,21,21,0) 100%);
  position: absolute;
  left: 0;
  top: -1px;
  z-index: 0;
}
.offlineContainer {
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
}
.speakers {
  display: flex;
  flex-direction: column;
  gap: 70px;
}
.speaker {
  display: flex;
  gap: 23px;
  margin-top: 70px;
}
.photo {
  width: 50%;
  position: relative;
}
.photo button {
  position: absolute !important;
  background-color: #0140A9 !important;
  color: white !important;
  text-transform: uppercase !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  text-align: center !important;
  border-radius: 50% !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 200px !important;
  height: 200px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: "Unbounded", sans-serif !important;
}
.photo button:hover {
  background-color: #033791 !important;
}
.speaker img {
  width: 100%;
  border-radius: 20px;
  height: 100%;
  object-fit: cover;
}
.speakerInfo {
  flex-direction: column;
  width: 50%;
  gap: 24px;
  display: flex;
}
.speaker label {
  font-size: 16px;
}
.speakerName {
  font-size: 60px;
  text-transform: uppercase;
}
.partn {
  margin-top: 20px;
}
.speakerText {
  font-size: 16px;
  white-space: pre-wrap;
  text-align: left;
}
.speakerFeatures {
  display: flex;
  gap: 20px;
}
.speakerFeature {
  border: 1px solid white;
  padding: 26px 36px;
  box-sizing: border-box;
  border-radius: 20px;
  font-size: 28px;
  font-weight: 600;
  text-transform: uppercase;
}
.small {
  font-size: 19px;
}
.programOffline {
  margin-top: 120px;
  display: flex;
  gap: 20px;
  margin-bottom: 170px;
}
.programInfo {
  background-color: #0140A9;
  border-radius: 20px;
  padding: 40px;
  box-sizing: border-box;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 50%;
}
.programInfo .programWeek p {
  color: white;
  border: 1px solid white;
}
.programInfo h4 {
  font-size: 36px;
  color: white;
  font-weight: 400;
}
.lessonsProgram {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  gap: 20px;
}
.lessonsProgram p {
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, .2);
  width: 100%;
  font-size: 20px;
}
.programOffline img {
  width: 50%;
  object-fit: cover;
  border-radius: 20px;
}
.brands {
  margin-top: 70px;
}
.brands img {
  width: 90%;
  margin: 0 20px;
  cursor: pointer;
}
/* TARIFY */
.tarify {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 70px;
  margin-top: 170px;
}
.tarifyWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 1;
}
.tarif {
  background-color: white;
  padding: 50px 60px;
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  color: #151515;
  z-index: 1;
}
.tarifTitle {
  font-size: 58px;
  font-weight: 700;
  text-transform: uppercase;
  width: 39%;
}
.tarifPrice {
  font-size: 46px;
  font-weight: 600;
  width: fit-content;
}
.tarifPrice span {
  font-size: 28px;
}
.tarif button {
  background-color: #0140A9 !important;
  color: white !important;
  text-transform: uppercase !important;
  font-size: 16px !important;
  padding: 20px 30px !important;
  border-radius: 40px !important;
  font-weight: 600 !important;
  text-align: center !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: "Unbounded", sans-serif !important;
}
.tarif button:hover {
  background-color: #033791 !important;
}
/* VIP */
.vip {
  width: 100%;
  background-image: url("./Assets/pixels.jpg");
  background-size: cover;
  padding: 80px 0;
  padding-top: 300px;
  box-sizing: border-box;
  position: relative;
  top: -100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.vip .gradient {
  bottom: -160px;
  top: unset;
  z-index: 0;
  background: linear-gradient(180deg, rgba(21,21,21,.1) 0%, rgba(21,21,21,1) 100%);
}
.vip p {
  font-size: 18px;
}
.vipContainer {
  display: flex;
  flex-direction: column;
  gap: 44px;
}
.vipContent {
  display: flex;
  width: 100%;
  gap: 20px;
}
.vipText {
  width: 50%;
}
.vipFeatures {
  width: 50%;
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, .3);
  padding: 30px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.vipFeatures img {
  position: absolute;
  right: -15px;
  top: -50px;
}
.vipList {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

/* QA */
.qa {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 170px;
}
.qaContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 40px;
}

/* FOOTER */
.footer {
  width: 100%;
  padding: 57px 0;
  background-color: #000000;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: center;
}
.footerContianer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.links {
  display: flex;
  align-items: center;
  gap: 20px;
}
.links a {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: white;
  text-decoration: none;
}
.links svg {
  font-size: 28px;
}
.footer p {
  font-size: 24px
}
.brandText {
  margin-top: 2rem;
}
.videos {
  display: flex;
  width: 50%;
  gap: 1rem;
}
.videos video {
  width: 50%;
  border-radius: 20px;
}
.zhovt27Mob {
  display: none;
}

@media screen and (max-width: 1279px) {
  .innerField {
    flex-direction: column-reverse;
    gap: 2rem;
  }
  .onlineSpeaker {
    flex-direction: column-reverse;
  }
  .speaker {
    flex-direction: column;
  }
  .photo {
    width: 100%;
  }
  .speakerInfo {
    width: 100%;
  }
  .programOffline {
    flex-direction: column;
  }
  .programInfo {
    width: 100%;
    padding: 20px;
  }
  .tarif {
    flex-direction: column;
    align-items: center;
  }
  .tarifTitle {
    width: fit-content;
  }
  .videos {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .videos video {
    width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .selectors {
    flex-wrap: wrap;
  }
  .homeFull {
    flex-direction: column;
    gap: 1rem;
  }
  .zhovt27 {
    display: none;
  }
  .zhovt27Mob {
    display: flex;
  }
  .homeFullText {
    max-width: 100%;
    text-align: center;
  }
  .speakerFeatures {
    flex-wrap: wrap;
  }
  .speakerFeature {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .speakerFeature br {
    display: none;
  }
  .small {
    font-size: 24px;
  }
  .tarifTitle {
    text-align: center;
  }
  .vipContent {
    flex-direction: column;
  }
  .vipText {
    width: 100%;
  }
  .vipFeatures {
    width: 100%;
  }
  .photo img {
    max-height: 700px;
    object-fit: cover;
    width: 100%;
    object-position: center;
  }
}

@media screen and (max-width: 768px) {
  .home {
    background-repeat: no-repeat;
  }
  .home h1 {
    font-size: 68px;
  }
  .home h2 {
    font-size: 24px;
  }
  .footerContianer {
    flex-direction: column;
    gap: 2rem;
  }
  .links {
    flex-direction: column;
    align-items: center;
  }
  .programOffline img {
    width: 100%;
  }
}

@media screen and (max-width: 568px) {
  .earth {
    width: 60%;
  }
  .homeLogos {
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
  }
  .homeLogos p {
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .homeCities {
    padding: 4rem 0;
    padding-top: 2rem;
  }
  .moon {
    width: 50%;
  }
  .onlineName {
    font-size: 68px;
  }
  .program {
    margin-top: 60px;
  }
  .programBlock {
    padding: 20px;
  }
  .programCount {
    flex-direction: column;
  }
  .onlineContainer {
    padding-top: 0;
  }
  .feature {
    box-sizing: border-box;
  }
  .speakerName {
    font-size: 40px;
  }
  .speakers {
    gap: 40px;
  }
  .programOffline {
    margin-top: 60px;
    margin-bottom: 100px;
  }
  .brands img {
    width: 80%;
  }
  .tarify {
    margin-top: 100px;
  }
  .tarif {
    padding: 40px;
  }
  .tarifTitle {
    font-size: 40px;
  }
  .tarifPrice {
    font-size: 38px;
  }
  .vip {
    padding-top: 120px;
    top: 0
  }
  .qa {
    padding-bottom: 100px;
  }
  .programBlock h4 {
    font-size: 30px;
  }
  .qa {
    padding-bottom: 3rem;
  }
}

@media screen and (max-width: 400px) {
  .tarif button {
    font-size: 14px !important;
    padding: 11px 20px !important;
  }
  .tarifTitle {
    font-size: 30px;
  }
  .tarifPrice {
    font-size: 26px;
  }
}

@media screen and (max-width: 375px) {
  .programBlock h4 {
    font-size: 24px;
  }
  .lessons p {
    font-size: 16px;
  }
  .speakerName {
    font-size: 30px;
  }
  .speakerFeature {
    padding: 16px 26px;
    font-size: 24px;
  }
  .small {
    font-size: 17px;
  }
  .photo button {
    width: 150px !important;
    height: 150px !important;
    font-size: 12px !important;
  }
  .offline {
    padding-top: 20px;
  }
  .programWeek {
    flex-direction: column;
    align-items: flex-start;
  }
  .lessonsProgram p {
    font-size: 16px;
  }
}