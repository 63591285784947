.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    gap: 40px;
}
.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.header button {
    color: #0140A9 !important;
    font-size: 34px !important;
    border: 1px solid rgba(255, 255, 255, .2) !important;
}
.header button svg {
    transition-duration: .3s !important;
}
.header p {
    font-size: 24px;
    font-weight: 400;
}
.text {
    font-size: 18px;
}
.active svg {
    transform: rotate(45deg) !important;
}