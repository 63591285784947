.main {
    width: 100%;
    max-width: 1440px;
    padding: 0 132px;
    box-sizing: border-box;
    color: white;
}

@media screen and (max-width: 1280px) {
    .main {
        padding: 0 50px;
    }
}

@media screen and (max-width: 568px) {
    .main {
        padding: 0 20px;
    }
}