.main {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    background-color: rgba(128, 128, 128, .3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.main img {
    width: 75px;
}