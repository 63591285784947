.main {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(128, 128, 128, .3);
    z-index: 2;
}
.window {
    width: 60%;
    height: fit-content;
    padding: 1.5rem;
    box-sizing: border-box;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: white;
}
.close {
    margin-left: auto !important;
    color: #0140A9 !important;
    font-size: 24px !important;
}

@media screen and (max-width: 768px) {
    .window {
        width: 90%;
    }
}